:root {
  --main-color: #0e0327;
  --main-color-dark: #111010e6;
  --main-color-light: #dbe0e0;

  --primary-color: #10021a;
  --secondary-color: rgb(188, 194, 199);
  --tertiary-color: #c11f45;

  --main-font: Cinzel Decorative;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  color: var(--secondary-color);
  font-family: var(--main-font);
  scrollbar-width: none;
  font-weight: 700;
}

body {
  font-family: "Poppins", sans-serif;
  background-image: url("https://w0.peakpx.com/wallpaper/141/120/HD-wallpaper-galaxy-black-black-deep-galaxy-night-sky-space-star-stars-ultra.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
}
button {
  outline: none;
  border: none;
  cursor: pointer;
}

.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem 0;
  background-color: var(--tertiary-color);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;

  .nav-left {
    display: flex;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
      margin-right: 1rem;
      border-radius: 20%;
    }

    h1 {
      font-size: clamp(1rem, 0.6591rem + 1.7045vw, 1.75rem);
      font-weight: 600;
    }
  }

  .navlinks {
    a {
      padding: 0.5rem 1rem;
      font-size: 1.2rem;
      font-weight: 500;
      transition: all 0.5s ease;

      &:hover {
        color: var(--tertiary-color);
        background-color: var(--secondary-color);
        border-radius: 50px;
        box-shadow: -4px 4px 2px black;
      }
    }
  }

  .usersmenu {
    .user {
      background-color: transparent;
      border-radius: 50%;
      display: none;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
    .userm {
      background-color: var(--tertiary-color);
      padding: 20px 30px;
      position: absolute;
      top: 70px;
      right: 10px;
      display: none;
      flex-direction: column;
      a {
        padding: 0.3rem 0.5rem;
        font-size: 0.8rem;
        font-weight: 500;
        transition: all 0.5s ease;
        &:hover {
          color: var(--tertiary-color);
          background-color: var(--secondary-color);
          border-radius: 50px;
          box-shadow: -4px 4px 2px black;
        }
      }
      button {
        background-color: var(--secondary-color);
        color: var(--tertiary-color);
        padding: 0.3rem 0.5rem;
        font-size: 0.8rem;
        font-weight: 500;
        transition: all 0.5s ease;
        &:hover {
          color: var(--tertiary-color);
          background-color: var(--secondary-color);
          border-radius: 50px;
          box-shadow: -4px 4px 2px black;
        }
      }
    }
    > a {
      border: 3px solid var(--secondary-color);
      font-size: 1.2rem;
      font-weight: 750;
      padding: 8px 10px;
      border-radius: 20%;
      margin: 0 5px;
      transition: all 0.3s ease;

      &:hover {
        background-color: var(--secondary-color);
        color: red;
      }
    }

    #login {
      background-color: var(--secondary-color);
      color: var(--tertiary-color);
    }
  }
}

.hamburger {
  display: none;

  .line {
    width: 30px;
    height: 3px;
    background-color: var(--secondary-color);
    margin: 5px;
    transition: all 0.5s ease;
  }
  .line.rotate1 {
    transform: rotate(45deg) translate(5px, 0px);
  }
  .line.rotate2 {
    transform: rotate(-45deg) translate(5px, 0px);
  }
}

.hide {
  display: none;
}

main {
  padding: 100px 10px 20px 220px;
}

@media screen and (max-width: 900px) {
  .hamburger {
    display: block;
  }

  .navlinks {
    display: none;
    opacity: 0;
    position: absolute;
    top: 50px;
    width: 100%;
    background-color: var(--tertiary-color);
    border-radius: 0 0 10px 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

    a {
      margin: 0 auto;
      display: block;
      width: clamp(250px, 40vw, 400px);
      padding: 1rem;
      text-align: center;
    }
  }
  .nav .usersmenu {
    position: relative;
    .user {
      display: block;
    }
    > a {
      display: none;
    }
    .userm.show {
      display: flex;
    }
  }

  .navlinks.show {
    display: block;
    opacity: 1;
  }
}

aside {
  position: fixed;
  z-index: 999;
  overflow-y: scroll;
  top: 0;
  padding: 100px 20px 20px 20px;
  left: 0;
  width: 200px;
  height: 100vh;
  background-color: #49081eef;
  border: 1px solid var(--secondary-color);
  .atp {
    text-align: center;
  }
  h2 {
    color: var(--tertiary-color);
    font-size: 1.2rem;
    font-weight: 800;
    text-align: center;
    margin: 10px 0;
    padding: 5px 2px;
    background-color: rgb(185, 180, 185);
    border-radius: 10px;
    box-shadow: -2px 2px 2px rgb(66, 63, 63);
  }
  .alinks {
    a {
      display: block;
      padding: 10px 0;
      font-size: 1rem;
      font-weight: 500;
      text-align: center;
      transition: all 0.5s ease;
      &:hover {
        color: var(--tertiary-color);
        background-color: var(--secondary-color);
        border-radius: 50px;
        box-shadow: -4px 4px 2px black;
      }
    }
  }
  .afooter {
    p {
      color: rgb(255, 255, 255);
      font-size: 0.8rem;
      text-align: center;
      margin: 10px 0;
    }
  }
  button {
    display: none;
    background-color: transparent;
    i {
      color: var(--tertiary-color);
      font-size: 2.5rem;
    }
  }
}

hr {
  margin: 15px 0;
}

@media screen and (max-width: 900px) {
  .nav {
    padding: 0.3rem 0;
    img {
      width: 40px !important;
      height: 40px !important;
    }
  }
  aside {
    position: relative;
    padding-top: 55px;
    width: 100%;
    height: 95px;
    border: 0;
    flex-direction: column;
    .atp {
      text-align: center;
    }
    hr {
      height: 0;
    }
    h2 {
      color: var(--tertiary-color);
      font-size: 1.2rem;
      text-align: center;
      max-width: 200px;
      margin: 0 auto;
      /* firstchild */
      &:first-child {
        background-color: transparent;
        box-shadow: none;
        font-size: 1.6rem;
        margin-bottom: 10px;
      }
    }
    .afooter {
      p {
        color: rgb(255, 255, 255);
        font-size: 0.8rem;
        text-align: center;
        margin: 10px 0;
      }
    }
    button {
      display: block;
    }
    i {
      position: absolute;
      top: 50px;
      right: 20px;
    }
  }
  aside.show {
    height: fit-content;
    hr {
      height: auto;
    }
  }
  aside i.rotate {
    transform: rotate(180deg);
  }
  main {
    padding: 0 0 0 0;
  }
}

footer ul {
  margin: 0;
  padding: 0;
  li {
    margin: 8px;
  }
}
footer {
  background: linear-gradient(black 5%, transparent, rgb(164, 12, 53));
  border-top: 2px solid var(--secondary-color);
  padding: 20px 20px 20px 220px;
  display: flex;
  justify-content: center;
  * {
    color: aliceblue;
  }
}
.f-cont {
  width: 1200px;
}
.ftop {
  padding: 30px 0;
  margin: 10px;
  border-bottom: 1px solid rgb(187, 180, 180);
  form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: 50px;
    h2 {
      font-size: clamp(0.9rem, 0.9rem + 1.4205vw, 1.875rem);
      color: var(--tertiary-color);
      span {
        color: var(--secondary-color);
        font-size: clamp(0.6rem, 0.5rem + 1.4205vw, 1.875rem);
      }
    }
    .form-cont {
      display: flex;
      input {
        width: clamp(200px, 30vw, 400px);
        height: 40px;
        padding: 8px;
        margin: 5px;
        border-radius: 10px;
        background-color: transparent;
        color: aliceblue;
        border: 1px solid rgb(187, 180, 180);
      }
      button {
        padding: 8px;
        max-width: 100px;
        border-radius: 20%;
        margin: 0 auto;
        margin: 5px;
        color: aliceblue;
        background-color: rgb(5, 5, 56);
        outline: none;
        border: 1px solid rgb(187, 180, 180);
        &:hover {
          background-color: rgb(4, 46, 94);
        }
      }
    }
  }
}
.fbtm {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  gap: 40px;
  justify-items: center;
  text-align: center;
  padding: 40px 0;
  margin: 10px;
  border-bottom: 1px solid rgb(187, 180, 180);
  ul{
    background:linear-gradient(transparent 30%, rgba(24, 24, 24, 0.745));
    padding: 10px;
    border-radius: 20px;
    border-top: 2px solid aqua;
    border-bottom: 5px solid aqua;
    h2{
      font-size: clamp(0.9rem, 0.9rem + 1.4205vw, 1.875rem);
      color: var(--tertiary-color);
    }
  }
   li {
    a{
      display: block;
        min-width: 120px;
        color: aliceblue;
        padding: 8px 0;
        transition: all 0.3s ease-out;
        &:hover {
          color: var(--tertiary-color);
          background-color: var(--secondary-color);
          border-radius: 50px;
          box-shadow: -4px 4px 2px black;
      }
      }
  }
}
.fabout {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  .fab1 {
    display: flex;
    gap: 40px;
    justify-content: space-around;
    align-items: center;
    > img {
      width: 90px;
      border-radius: 20px;
    }
    h3 {
      font-size: clamp(0.9375rem, 0.7955rem + 0.7102vw, 1.25rem);
      margin: 5px;
      padding: 0;
    }
  }
  .fab2,
  .fab3 {
    display: flex;
    margin: 10px;
    img {
      width: 40px;
      margin: 0 8px;
      border-radius: 30%;
    }
  }
}

@media screen and (max-width: 720px) {
  form,
  .form-cont {
    flex-direction: column;
    align-items: center;
  }
  .fbtm > li {
    width: 25%;
  }
}
@media screen and (max-width: 440px) {
  .fbtm > li {
    width: 50%;
  }
}

@media screen and (max-width: 900px) {
  footer {
    padding: 20px;
  }
}


