.hero {
  height: 90vh;
  background-color: rgba(255, 255, 255, 0.171);
  background-image: url("./resources/peakpx.jpg");
  background-size: cover;
  background-position: center;
  .hblr {
    z-index: 50;
    background: linear-gradient(
      0deg,
      rgba(21, 2, 8, 0.9),
      rgba(0, 0, 0, 0.749) 70%,
      transparent
    );
    height: 100%;
    padding: 50px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      font-size: clamp(2rem, 1.3182rem + 3.4091vw, 3.5rem);
      span {
        color: var(--tertiary-color);
      }
    }
    p {
      font-size: clamp(1rem, 0.6591rem + 1.7045vw, 1.75rem);
      margin: 1rem 0;
    }
    .hbbtn {
      a {
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
        font-weight: 900;
        border: 2px solid var(--secondary-color);
        background-color: var(--secondary-color);
        color: var(--tertiary-color);
        border-radius: 12%;
        font-weight: 500;
        transition: all 0.3s ease;
        &:first-child {
          margin-right: 1rem;
          border: 1px solid rgb(4, 39, 48);
          background-color: var(--tertiary-color);
          color: var(--secondary-color);
        }
        &:hover {
          box-shadow: -2px 2px 2px rgb(199, 194, 194);
        }
      }
    }
  }
}

.mcard-cont {
  margin-top: 20px;
  .mdata {
    padding: 12px 0;
    h2 {
      font-size: clamp(1.5rem, 0.5909rem + 4.5455vw, 3.5rem);
      font-weight: 600;
      color: var(--secondary-color);
      width: 100%;
      text-align: center;
      span {
        color: var(--tertiary-color);
      }
    }
    p {
      font-size: clamp(1rem, 0.6591rem + 1.7045vw, 1.75rem);
      margin: 1rem 0;
      text-align: center;
      span {
        color: var(--tertiary-color);
      }
    }
  }
}

.mcards {
  min-height: 800px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  justify-items: center;
  align-items: center;
  padding: 20px;
  .mcard {
    background: linear-gradient(180deg, rgb(255, 0, 55), aqua);
    padding: 5px;
    position: relative;
    width: clamp(150px, 40vw, 250px);
    height: auto;
    aspect-ratio: 5 / 7;
    background-color: rgb(0, 0, 0);
    border-radius: 20px 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 15px 0;
    }
    .mcard-info {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 5px;
      right: 5px;
      border-radius: 0 0 15px 0;
      background: linear-gradient(0deg, rgb(0, 0, 0) 30%, transparent 100%);
      div {
        position: absolute;
        bottom: 8px;
        left: 10px;
        h3 {
          font-size: 1.2rem;
          font-weight: 600;
          color: var(--tertiary-color);
        }
        p {
          font-size: 0.6rem;
          margin: 5px 0;
          max-height: 50px;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
        a {
          display: grid;
          position: absolute;
          height: auto;
          width: clamp(40px, 5vw, 65px);
          aspect-ratio: 1/1;
          bottom: 100px;
          right: 3%;
          font-size: clamp(0.5rem, 0.3636rem + 0.2818vw, 0.8rem);
          border: 1px solid white;
          border-radius: 50%;
          background-color: var(--tertiary-color);
          padding: 5px;
          font-weight: 800;
          transition: all 0.5s ease;
          align-items: center;
          justify-content: center;
          &:hover {
            color: var(--tertiary-color);
            background-color: var(--secondary-color);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 499px) {
  .mcards {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    padding: 5px;
    .mcard-info {
      p {
        display: none;
      }
    }
  }
}

.mcard2 {
  width: clamp(150px, 40vw, 250px);
  height: 60px;
  background-color: rgb(0, 0, 0);
  .mcard2-info {
    display: grid;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 2px 12px;
    background: linear-gradient(
      45deg,
      rgb(67, 7, 73),
      var(--tertiary-color),
      rgb(121, 42, 2) 70%
    );
    a {
      display: block;
      padding: 10px 0;
      font-size: 1rem;
      font-weight: 1000;
      text-align: center;
      transition: all 0.5s ease;
      &:hover {
        color: var(--tertiary-color);
        background-color: var(--secondary-color);
        border-radius: 50px;
        box-shadow: -4px 4px 2px black;
      }
    }
  }
}

.slider-container {
  margin: clamp(30px, 8vw, 85px) 0;
  padding: 20px 0;
  height: auto;
  width: 100%;
  aspect-ratio: 25/11;
  background: linear-gradient(
    90deg,
    var(--tertiary-color) -40%,
    transparent,
    var(--tertiary-color) 140%
  );
  h2 {
    margin-bottom: 1.5rem;
    font-size: clamp(1.5rem, 0.5909rem + 4.5455vw, 3.5rem);
    font-weight: 600;
    color: var(--secondary-color);
    width: 100%;
    text-align: center;
    span {
      color: var(--tertiary-color);
    }
  }
  .slider {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 50px;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    .slide {
      border-radius: 20px;
      background: linear-gradient(45deg, var(--tertiary-color), aqua);
      padding: 5px;
      margin-top: 2%;
      height: 90%;
      min-width: 70%;
      scroll-snap-align: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
      }
    }
  }
}
@media screen and (max-width: 400px) {
  .slider-container {
    aspect-ratio: 25/16;
    .slider {
      gap: 30px !important;

      .slide {
        padding: 2px !important;
      }
    }
  }
}

.features {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  justify-items: center;
  padding: 20px;

  .feature {
    box-sizing: border-box;
    width: 250px;
    background-color: #300416;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    height: -webkit-fill-available;
    aspect-ratio: 16 / 25;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    img {
      width: 100%;
      height: 35%;
      object-fit: cover;
    }
    h3 {
      font-size: 1.3rem;
      color: #dcd6d6;
      font-weight: 600;
    }
    p {
      font-size: 0.8rem;
      color: #666;
      margin: 10px 0;
      text-overflow: ellipsis;
    }
    a {
      font-size: 1rem;
      border: 1px solid white;
      font-weight: 900;
      border-radius: 25px;
      padding: 5px 10px;
      font-weight: 900;
      text-align: center;
      transition: all 0.5s ease;
      &:hover {
        color: var(--tertiary-color);
        background-color: var(--secondary-color);
      }
    }
  }
}
.featured {
  margin-top: 120px;
  h2 {
    font-size: clamp(1.5rem, 0.5909rem + 4.5455vw, 3.5rem);
    font-weight: 600;
    color: var(--secondary-color);
    width: 100%;
    text-align: center;
    span {
      color: var(--tertiary-color);
    }
  }
}

.aboutus {
  background-color: #0b0b0bbd;
  padding: 50px 20px;
}
.aboutus h2 {
  font-size: clamp(2rem, 0.6364rem + 6.8182vw, 5rem);
  font-weight: 700;
  margin-bottom: 30px;
  color: var(--tertiary-color);
}
.aboutus p {
  color: rgb(155, 161, 161);
  font-size: clamp(0.8rem, 0.418rem + 1.5909vw, 1rem);
  line-height: 1.8;
  span {
    color: var(--tertiary-color);
  }
}

/*======================
    404 page
=======================*/

.page_404 {
  padding: 40px;
  background: #fff;
  font-family: "Arvo", serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}

.mvmncnt {
  position: relative;
  min-height: 90vh;
  background-color: black;
}

.mvcnt {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  height: clamp(300px, 50vw, 500px);
  .mvblr {
    position: absolute;
    top: 0;
    background: linear-gradient(
      0deg,
      black,
      rgb(0, 0, 0) 50%,
      rgba(0, 0, 0, 0)
    );
    z-index: 0;
    height: 100%;
    width: 100%;
  }
  .mvimage {
    position: absolute;
    padding: 5px;
    background: linear-gradient(red, blue);
    border-radius: 20px;
    z-index: 30;
    height: auto;
    bottom: 15%;
    left: 8%;
    aspect-ratio: 10/16;
    width: clamp(150px, 20vw, 400px);
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 15px;
    }
  }
  .mvinfo {
    position: absolute;
    z-index: 20;
    left: 35%;
    bottom: 20%;
    padding: 50px 20px 0 20px;
    /* background:linear-gradient(90deg, transparent, var(--tertiary-color) 150%); */
    h2 {
      font-size: clamp(1rem, 0.6364rem + 1.8182vw, 1.8rem);
      font-weight: 600;
      color: var(--tertiary-color);
    }
    p {
      font-size: clamp(0.5rem, 0.2727rem + 1.1364vw, 1rem);
      margin: 1rem 0;
      font-weight: 400;
    }
    .mvbtn {
      a {
        font-size: clamp(0.5rem, 0.2727rem + 1.1364vw, 1rem);
        cursor: pointer;
        padding: 0.5rem 1rem;

        font-weight: 900;
        border: 2px solid var(--secondary-color);
        background-color: var(--secondary-color);
        color: var(--tertiary-color);
        border-radius: 12%;
        font-weight: 500;
        transition: all 0.3s ease;
        &:first-child {
          margin-right: 1rem;
          border: 1px solid rgb(4, 39, 48);
          background-color: var(--tertiary-color);
          color: var(--secondary-color);
        }
        &:hover {
          box-shadow: -2px 2px 2px rgb(0, 255, 242);
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .mvcnt {
    .mvimage {
      position: relative;
      left: 25%;
      top: -80%;
      padding: 2px;
      border-radius: 10px;
      img {
        border-radius: 8px;
      }
    }

    .mvinfo {
      left: 0;
      bottom: 10%;
      padding: 0 20px;
    }
  }
}
